<template>
  <Layout :tituloPagina="`Network | Routers | Edición | ${router.nombre} | Proveedores de internet`">
    <div class="text-right">
      <router-link
        :to="{ name: 'nuevoProveedorInternetRouter' }"
        class="btn btn-success btn-sm"
      >
        <i class="mdi mdi-plus-thick"></i>
        Nuevo
      </router-link>
    </div>
    <br />
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title text-truncate w-100">
          Listado de proveedores de internet para el router
          {{ router.nombre }}
        </h4>
        <div>
          <a href="#" onclick="return false;" @click="cerrar()">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="tblRouters table table-hover table-nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Principal</th>
                <th>Carga / Descarga</th>
                <th>Descripción</th>
                <th>Estado del servicio</th>
                <th title="Cantidad de CPEs asignados al ISP">CPEs</th>
                <th>Respaldo</th>
                <th style=" width: 175px" class="text-center">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="isp in isps" :key="isp.id">
                <td class="fw-semibold">{{ isp.id }}</td>
                <td class="fw-semibold">{{ isp.nombre }}</td>
                <td>
                  <span
                    class="badge bg-success bg-gradient font-size-13"
                    v-show="isp.principal == true"
                  >
                    Principal
                  </span>
                </td>
                <td>
                  <span :class="{ 'text-danger': isp.carga_maxima == 0 }">
                    {{ isp.carga_maxima }}
                    {{ isp.unidad_carga == 'k' ? 'kbps' : '' }}
                    {{ isp.unidad_carga == 'M' ? 'Mbps' : '' }}
                  </span>
                  /
                  <span :class="{ 'text-danger': isp.descarga_maxima == 0 }">
                    {{ isp.descarga_maxima }}
                    {{ isp.unidad_descarga == 'k' ? 'kbps' : '' }}
                    {{ isp.unidad_descarga == 'M' ? 'Mbps' : '' }}
                  </span>
                </td>
                <td>{{ isp.descripcion }}</td>
                <td>
                  <span class="badge badge-pill font-size-12" v-show="isp.estado_servicio != null"
                    :class="{
                      'bg-light': isp.estado_servicio == 0,
                      'badge-soft-primary': isp.estado_servicio == 1,
                      'badge-soft-danger': isp.estado_servicio == 2
                    }">
                    {{ isp.estado_servicio == 0 ? 'Actualizando estado ': '' }}
                    <span v-show="isp.estado_servicio == 0" class="mdi mdi-loading mdi-spin"></span>

                    {{ isp.estado_servicio == 1 ? 'Funcional': '' }}
                    {{ isp.estado_servicio == 2 ? 'Caido' : '' }}
                  </span>
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'listadoCpes',
                      query: { id_router: router.id, id_isp: isp.id }
                    }"
                  >
                    {{ isp.cantidad_cpes }} cpes
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'listadoProveedoresRespaldoInternetRouter',
                      params: { id_isp: isp.id }
                    }"
                    class="btn btn-light btn-sm"
                  >
                    <i class="mdi mdi-bookmark"></i>
                    Respaldos
                  </router-link>
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'edicionProveedorInternetRouter',
                      params: { id_isp: isp.id }
                    }"
                    class="btn btn-warning bg-gradient btn-sm"
                  >
                    <i class="fa fa-edit"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger bg-gradient btn-sm"
                    @click="preguntaEliminar(isp)"
                  >
                    <i class="fa fa-trash"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-secondary" @click="atras()">
        <i class="mdi mdi-chevron-left"></i>
        Atrás
      </button>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import IspRouterSrv from '@/services/IspRouterSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoProveedoresInternetRouter',
  components: { Layout},
  data() {
    return {
      baseUrl: window.location.origin,
      router: { id: null },
      isps: [],
      intervals: {
        actualizacion: null
      }
    }
  },
  created: function() {
    var self = this
    self.cargarRouter(function() {
      self.refrescarIsps()
    })

    // Refresca cada 30 segundos los estados de los ISPs
    self.intervals.actualizacion = setInterval(function() {
      self.refrescarEstadosIsps() 
      self.refrescarEnLineaRouter()
    }, 30000)
  },
  beforeUnmount: function() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    atras: function() {
      this.$router.go(-1)
    },
    cerrar: function() {
      this.atras()
    },
    preguntaEliminar: function(isp) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de proveedor de internet",
        html: '¿Está seguro que desea eliminar el ISP <strong>'+isp.nombre+'</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          IspRouterSrv.eliminar(isp.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarIsps()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el ISP'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    cargarRouter: function(callback) {
      var self = this
      var idRouter = this.$route.params.id

      RouterSrv.routerJSON(idRouter).then(response => {
        self.router = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },
    refrescarEnLineaRouter() {
      let self = this,
        idRouter = this.$route.params.id

      RouterSrv.enLinea(idRouter).then(response => {
        self.router.en_linea = response.data
      })
    },
    refrescarIsps () {
      var self = this

      IspRouterSrv.ispsJSON(self.router.id).then(response => {
        self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },
    refrescarEstadosIsps () {
      var self = this

      IspRouterSrv.estadosIspsJSON(self.router.id).then(response => {
        let estados = response.data
        estados.forEach(estado => {
          let isp = self.isps.find(isp => isp.id == estado.id_isp)

          if(isp != undefined)
            isp.estado_servicio = estado.estado
        })
      })
    }
  }
}
</script>